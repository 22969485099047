import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { ReactNode, createContext, useState } from "react";

type AlertProps = {
  severity: AlertColor;
  message: string;
};

const ApiContext = createContext<(props: AlertProps) => void>(() => {});

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState<AlertProps>({
    severity: "error",
    message: "",
  });
  const onClose = () => setOpen(false);
  const openAlert = (props: AlertProps) => {
    setAlertData(props);
    setOpen(true);
  };
  return (
    <ApiContext.Provider value={openAlert}>
      <Snackbar autoHideDuration={6000} open={open} onClose={onClose}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={onClose}
          severity={alertData.severity}
        >
          {alertData.message}
        </Alert>
      </Snackbar>
      {children}
    </ApiContext.Provider>
  );
};

export const useAlert = () => React.useContext(ApiContext);

export default useAlert;
