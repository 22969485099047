export const baseLinks = {
  assessment: "/assessment",
  admin: "/admin",
  account: "/account",
  login: "/login",
  createAccount: "/signup",
  store: "/store",
  home: "/",
};

export default baseLinks;
