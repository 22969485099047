import "date-fns";
import "./App.css";

import { Elements as StripeProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import { Route, Switch } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { AlertProvider } from "./hooks/alert";
import { baseLinks as links } from "./links";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { lazy } from "react";
import { MySuspense } from "./components/MySuspense";

const stripePromise = fetch("/api/store/stripe/public_key")
  .then((k) => k.json())
  .then((k) => loadStripe(k.key));

const Assessment = lazy(() => import("./Assessment"));
const Account = lazy(() => import("./Account"));
const Admin = lazy(() => import("./Admin"));
const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));

const mojoColors = {
  main: {
    aqua: "#2C88AA",
    chartreuse: "#AEC80D",
  },
  identities: {
    "The Achievers": {
      dark: "#00398e",
      light: "#90bae8",
      image: "Achievers",
    },
    "The Caregivers": {
      dark: "#579c29",
      light: "#dfde00",
      image: "Caregivers",
    },
    "The Reward-Driven": {
      dark: "#d27400",
      light: "#e8b200",
      image: "Rewards",
    },
    "The Thinkers": {
      dark: "#890300",
      light: "#dc947c",
      image: "Thinkers",
    },
    "The Builders": {
      dark: "#4e006d",
      light: "#c22a87",
      image: "Builders",
    },
  },
};

const bodyOverride = {
  fontFamily: ['"Open Sans"', "sans-serif"].join(","),
};

let theme = createTheme({
  palette: {
    primary: { main: mojoColors.main.aqua },
    secondary: { main: mojoColors.main.chartreuse },
    others: {
      white: "white",
      gray: "lightgray",
    },
    mojoColors,
    background: {
      gradient: `linear-gradient(${mojoColors.main.chartreuse},${mojoColors.main.aqua})`,
    },
  },
  typography: {
    fontFamily: ['"Gotham"', "sans-serif"].join(","),
    body1: bodyOverride,
    body2: bodyOverride,
  },
  direction: "ltr",
});

theme = responsiveFontSizes(theme);

console.log(theme);

function App() {
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <StripeProvider stripe={stripePromise}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AlertProvider>
                <CssBaseline />
                <MySuspense>
                  <Switch>
                    <Route path={links.account} component={Account} />
                    <Route path={links.admin} component={Admin} />
                    <Route path={links.assessment} component={Assessment} />
                    <Route path={links.login} component={Login} />
                    <Route path={links.home} component={Home} />
                  </Switch>
                </MySuspense>
              </AlertProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StripeProvider>
    </div>
  );
}
export default App;
