import { Container, LinearProgress } from "@mui/material";

export const Fallback = () => (
  <Container
    className="fallback-fadein"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100vh",
      width: "100%",
      flexDirection: "column",
    }}
  >
    <div>
      <img
        src="/images/findMojo.png"
        alt="Find Mojo"
        style={{ maxWidth: "100%", marginBottom: 16 }}
      />
    </div>
    <LinearProgress
      variant="indeterminate"
      style={{ width: "100%", maxWidth: 648 }}
    />
  </Container>
);
